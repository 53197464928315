import { create } from "zustand";

import {
  TERRITORY_COLUMN_MAP,
  WHITESPACE_COLUMN_MAP,
} from "constants/territory";
import {
  calculateRowsCapacity,
  removeInvalidObjectTypes,
} from "pages/WhitespaceAndTerritory/components/utils";
import { TerritoryAPI, WhitespaceAPI } from "services";
import { EditColumnPayload, PagePayload } from "services/territory";
import { territoryAccountsCacheController } from "services/territory/util";
import Views, { TableViewT } from "services/views";
import {
  Account,
  AccountResp,
  AccountSourceT,
  DynFilterColumn,
  DynamicAccount,
  VendorProduct,
} from "types/api";
import {
  DynamicFilterT,
  TerritoryTableDefaultColumns,
  WhitespaceTableDefaultColumns,
} from "types/api/territory";
import {
  ICustomFilter,
  PipelineSignalEnums,
  TerritoryFilters,
  TerritoryFiltersDefaultValues,
} from "types/api/territoryFilters";
import { delay } from "utils/general";
import { setObjectDeep } from "utils/object";

import { StateRecord } from "./main";
import {
  cleanedColumnsToView,
  convertToCompatibleTerritoryFilters,
  flattenAccountPayloadDifferentSources,
  formatDynamicFilters,
  getDynFilterDetails,
  getUpdatedColumnPath,
} from "./utils";
import { defaultTerritoryFiltersObject } from "data/territoryTable";
import { OrderDirectionEnum } from "types/account";
import { userStorage } from "utils/auth";
import { ConnectionName } from "types/connections";
// @todo: will be moved on to defauts/ or data file
export const tableFiltersContainerHeight = document.documentElement.clientHeight - 220;
interface ISetAllFiltersDetails {
  name: string;
  value: number;
}
interface IAllFiltersDetailsOneFilter {
  getLabel: (count: number) => string;
  label: string;
  count: number;
}
export type IAllFiltersDetails = {
  accountOwners: IAllFiltersDetailsOneFilter;
  pipelineAttributeFilters: IAllFiltersDetailsOneFilter;
  firmographicFilters: IAllFiltersDetailsOneFilter;
  technographicFilters: IAllFiltersDetailsOneFilter;
  searchQuery: IAllFiltersDetailsOneFilter;
  pipelineSignals: IAllFiltersDetailsOneFilter;
  accountStatus: IAllFiltersDetailsOneFilter;
  customFilters: IAllFiltersDetailsOneFilter;
  // should accomodate all [key: string]: IAllFiltersDetailsOneFilter; ??
};

export enum LookUpDataSource {
  CRM = 'crm',
  External = 'external'
}

export interface TerritoryFiltersState {
  accounts?: Account[];
  filters?: TerritoryFilters;
  lookUpDataSource?: LookUpDataSource
  selectionState?: {
    selected?: { [key: string]: boolean };
    isSelectAll?: boolean;
  };
  pagination: {
    total: number;
    currentPage: number;
    pageSize: number;
    results: number;
  };
  territoryColumns: { [key: string]: boolean };
  whitespaceColumns: { [key: string]: boolean };
  allFiltersDetails: IAllFiltersDetails;
  columnsMeta?: DynFilterColumn;
  hierarchyColumns?: {
    [key: string]: { [key: string]: any };
  };
  views: TableViewT[]
}

export interface TerritoryDefaultFiltersState {
  filters?: TerritoryFiltersDefaultValues;
}

export const mergerColumnsMaps = (map1: any, map2: any) => Object.fromEntries(
  Object.entries({ ...map1, ...map2 }).map(([key, value]) => [
    key,
    typeof value === "boolean" ? value : true,
  ]),
);

// todo: revisit
const getColumnSelectors = (isTerritoryScreen?: boolean) => {
  const territoryStoredColumns = userStorage.get(TERRITORY_COLUMN_MAP);
  const whitespaceStoredColumns = userStorage.get(WHITESPACE_COLUMN_MAP);
  const defaultColumns = isTerritoryScreen
    ? mergerColumnsMaps(TerritoryTableDefaultColumns, territoryStoredColumns)
    : mergerColumnsMaps(WhitespaceTableDefaultColumns, whitespaceStoredColumns);

  return defaultColumns;
};

interface ITerritoryFilters extends StateRecord {
  isLoading?: boolean;
  state: TerritoryFiltersState;
  initialState: TerritoryDefaultFiltersState;
  errors?: any;
  actions: {
    getFilterUpdates: (
      filters: TerritoryFilters,
      options?: Parameters<typeof TerritoryAPI.filters>[2],
    ) => Promise<{
      tableData: AccountResp | AccountResp<DynamicAccount>;
      error: any;
    }>;
    getDynamicTable: (
      tableId: string,
      sustainability: number
    ) => Promise<AccountResp<DynamicAccount>>;
    onFilterChange: (filedName: string, fieldValue?: any) => void;
    getAccountSource: (name: string) => AccountSourceT | undefined;
    sort: (columnId: string, direction?: OrderDirectionEnum) => Promise<void>;
    editColumn: (payload: EditColumnPayload) => Promise<any>;
    onToggleAccountSelection: (
      accountsIds: boolean | string | string[]
    ) => void;
    onRemoveAccountSelection: (accountsIds: string[]) => void;
    resetFilters: (viewId?: string) => Promise<void>;
    setPagination: (
      pagination: Partial<TerritoryFiltersState["pagination"]>
    ) => void;
    updateFilters: (filters: Partial<TerritoryFilters>) => void;
    updateDynamicFilters: (filters: DynamicFilterT) => void;
    updateAccount: (
      payload: Parameters<typeof WhitespaceAPI.whitespace.updateAccount>[0]
    ) => Promise<Account>;
    getAccountById: (accountId: string) => Promise<any>;
    updateVisibleColumns: (columnList: DynFilterColumn) => void;
    getAccountQualityStats: ({
      userIds,
    }: {
      userIds: string[];
    }) => Promise<number[]>;
    getAllUserAccounts: (tableId: string) => Promise<Account[]>;
    getUserAccountsPerPage: ({
      limit,
      offset,
    }: {
      limit: number;
      offset: number;
    }) => Promise<Account[]>;
    getAccountIdsBySelectedVendors: (
      searchPayload: Parameters<typeof TerritoryAPI.accountsProductsFilter>[0],
      options?: Parameters<typeof TerritoryAPI.accountsProductsFilter>[1]
    ) => Promise<VendorProduct[]>;
    setInitialFilterValues: (filters: TerritoryFiltersDefaultValues) => void;
    setAllFiltersDetails: ({ name, value }: ISetAllFiltersDetails) => void;
    getFiltersMetaData: () => Promise<void>;
    archiveAccounts: () => Promise<void>;
    deleteAccounts: () => Promise<void>;
    addAccountsToSF: () => Promise<void>;
    createView: (name: string) => Promise<{
      success: boolean;
      response: any;
    }>;
    updateView: (id: string, filters: DynamicFilterT) => Promise<{
      success: boolean;
      response: any;
    }>;
    deleteView: (id: string) => Promise<{
      success: boolean;
      response: any;
    }>;
    listViews: () => Promise<TableViewT[]>;
    updateFiltersView: (viewId?: string) => Promise<void>;
    getView: (viewId: string) => Promise<TableViewT | undefined>;
  };
}

const initialTerritoryFilters = {
  isLoading: true,
  accounts: [] as Account[],
  lookUpDataSource: LookUpDataSource.External,
  filters: {
    pipelineAttributeFilters: {},
    firmographicFilters: {},
    searchQuery: "",
    accountOwners: [] as TerritoryFilters["accountOwners"],
    accountStatus: {
      activity: undefined,
      awareness: undefined,
      engagement: undefined,
      opportunities: undefined,
    },
    pipelineSignals: [] as TerritoryFilters["pipelineSignals"],
    customFilters: [] as ICustomFilter[],
  } as TerritoryFilters,

  allFiltersDetails: {
    accountOwners: {
      getLabel: (count: number) => Boolean(count)
        && `filter by selected account owners (${count} selected)`,
    },

    pipelineAttributeFilters: {
      getLabel: (count: number) => Boolean(count) && `${count} filters enabled in the Pipeline attributes`,
    },

    firmographicFilters: {
      getLabel: (count: number) => Boolean(count) && `${count} filters enabled in the Firmographics`,
    },

    technographicFilters: {
      getLabel: (count: number) => Boolean(count) && `${count} filters enabled in the Technographic`,
    },

    searchQuery: {
      getLabel: (count: number) => Boolean(count) && "Search text is enabled",
    },

    pipelineSignals: {
      getLabel: (count: number) => Boolean(count) && "filter by selected account fit signals",
    },

    accountStatus: {
      getLabel: (count: number) => Boolean(count) && "filter by accounts status",
    },

    customFilters: {
      getLabel: (count: number) => Boolean(count) && "filter by Custom Filters",
    },
  },

  selectionState: {},
  pagination: {
    total: 0,
    currentPage: 1,
    pageSize: 50,//calculateRowsCapacity(tableFiltersContainerHeight),
    results: 0,
  },
  territoryColumns: getColumnSelectors(true),
  whitespaceColumns: getColumnSelectors(),
  views: [],
} as unknown as TerritoryFiltersState;

const defaultTerritoryFilters = {
  isLoading: true,
  filters: {
    accountOwners: [],
    pipelineAttributeFilters: {
      lastSalesActivity: "all",
      lastCustomerActivity: "all",
      nextRenewalDate: "all",
      availableWhitespace: { min: 0, max: 100000000 },
    },

    firmographicFilters: {
      annualRevenue: { min: 0, max: 100000000 },
      employees: {},
      industry: [],
      headquarters: [],
    },

    technographicFilters: {
      ownedProducts: [],
    },

    searchQuery: "",
    pipelineSignals: [],
    accountStatus: {},
    customFilter: [],
  } as TerritoryFiltersDefaultValues,
} as unknown as TerritoryFiltersState;

const useTerritoryFilters = create<ITerritoryFilters>((setState, getState) => {
  return {
    isLoading: true,
    state: initialTerritoryFilters,
    initialState: defaultTerritoryFilters,
    actions: {
      getFilterUpdates: async (updatedFilters, options: any) => {
        const _filters = Object.assign({}, { ...updatedFilters });
        const { state } = getState();

        setState({ isLoading: true });
        const { pagination, lookUpDataSource } = state;
        const deepCopyFilters = JSON.parse(JSON.stringify(_filters));
        const filters = formatDynamicFilters(
          removeInvalidObjectTypes(deepCopyFilters as DynamicFilterT),
        );

        const columnsToSave = cleanedColumnsToView(state.columnsMeta);
        const columnInfo = columnsToSave
          ? { Account: columnsToSave }
          : undefined;

        const filtersWithPagination = {
          ...filters,
          dataSource: lookUpDataSource,
          limit: pagination?.pageSize,
          offset: (pagination.currentPage - 1) * pagination.pageSize,
        } as TerritoryFilters & { columnInfo?: { Account: DynFilterColumn } };

        const resp: AccountResp | AccountResp<DynamicAccount> = await TerritoryAPI.dynamicTable(
          filtersWithPagination,
          '',
          0,
          columnInfo,
          options,
        );

        if (resp?.error) {
          if (resp.error?.code === 'ERR_CANCELED') {
            return { tableData: resp, error: resp.error };
          }
          setState({ isLoading: false });
        } else {
          setState(({ state: prevState, ...rest }) => {
            const accountWithTargetSource = (resp?.results || []).map((account) => flattenAccountPayloadDifferentSources(account, prevState.lookUpDataSource!))
            return {
              ...rest,
              isLoading: false,
              state: {
                ...prevState,
                accounts: accountWithTargetSource,
                pagination: {
                  ...prevState.pagination,
                  total: resp?.total || 0,
                  results: resp?.results?.length,
                },
                allFiltersDetails: getDynFilterDetails(resp?.columns ?? {}),
              } as unknown as TerritoryFiltersState,
            }
          });
        }
        setState({ isLoading: false });
        return { tableData: {}, error: {} } as any;
      },
      getDynamicTable: async (tableId: string, sustainability: number) => {
        const { state } = getState();
        setState({ isLoading: true });
        const { pagination } = state;
        const filters: PagePayload = {
          limit: pagination?.pageSize,
          offset: (pagination.currentPage - 1) * pagination.pageSize,
        };
        const resp: AccountResp<DynamicAccount> = await TerritoryAPI.dynamicTable(filters, tableId, sustainability);
        setState(({ state: prevState, ...rest }) => ({
          ...rest,
          isLoading: false,
          state: {
            ...prevState,
            accounts: resp.results || [],
            pagination: {
              ...prevState.pagination,
              total: resp.total || 0,
              results: resp.results.length,
            },
            allFiltersDetails: getDynFilterDetails(resp?.columns ?? {}),
          } as unknown as TerritoryFiltersState,
        }));
        return resp;
      },
      editColumn: async (payload: EditColumnPayload) => {
        try {
          const { state } = getState();
          const { accounts } = state;

          const { data } = await TerritoryAPI.editColumn(payload);

          const updatedAccounts = (accounts ?? []).map((account) => {
            if (account.id === payload.recordId) {
              return { ...account, ...payload.updates };
            }
            return account;
          });
          setState({ state: { ...state, accounts: updatedAccounts } });
          return data;
        } catch (err) {
          return {};
        }
      },
      onFilterChange: (fieldName, fieldValue) => {
        const { state } = getState();
        const newState = {
          ...state,
          filters: setObjectDeep(state.filters, fieldName, fieldValue, true),
        };
        setState({ state: newState });
        return newState;
      },
      getAccountSource: (name: string): AccountSourceT | undefined => {
        const {
          state: { accounts },
        } = getState();
        const currentAccount = accounts?.find(
          (account) => account.name === name,
        );
        return currentAccount?.source;
      },
      sort: async (columnId, direction) => {
        if (columnId === 'rankValue') {
          columnId = 'suitability'
        }
        const { state } = getState();
        const { filters } = state;
        const colParents: string = getUpdatedColumnPath(
          columnId,
          defaultTerritoryFiltersObject as unknown as DynamicFilterT,
        );
        if (!colParents || !columnId) {
          console.error(`Missing the column parent key ${colParents} path for the column path ${columnId}`)
          return
        }

        const updatedFilters = setObjectDeep(
          filters,
          `${colParents}.sort`,
          direction,
          true,
        )
        const newState = {
          ...state,
          filters: updatedFilters,
        };
        setState({ state: newState });
      },
      updateFilters: (filters) => {
        setState((state) => ({
          state: {
            ...state.state,
            filters: filters as TerritoryFilters,
          },
        }));
      },
      updateDynamicFilters: (filters: DynamicFilterT) => {
        const { state } = getState();
        const newState = {
          ...state,
          filters: { ...state.filters, ...filters } as any,
        };
        setState({ state: newState });
      },
      resetFilters: async (viewId) => {
        const { actions } = getState();
        if (viewId) {
          return actions.updateFiltersView(viewId);
        }
        setState((state) => ({
          state: {
            ...state.state,
            filters: defaultTerritoryFiltersObject as any
          },
        }));
      },
      // @todo: doesn't make sense to put this in the filterMetaData service, but also doesn't feel right here. Revisit it and make a decision.
      getFiltersMetaData: async () => {
        const { state, isLoading } = getState();
        const accountsMetaData = await TerritoryAPI.filtersMetaData();
        const newState = {
          ...state,
          accountsMetaData,
        };
        setState({ state: newState });
      },
      updateAccount: async (payload) => {
        const { resource: updatedAccount } = await WhitespaceAPI.whitespace.updateAccount(payload);
        const { state } = getState();
        const currentAccountsList = state.accounts || [];
        const updatedAccountsList = currentAccountsList.map((account) => {
          if (account.id === updatedAccount.id) {
            // temporary: we don't take the full updated account because we don't want to mess up the rankings. If unsure, ask Farah or Eslam about that.
            return updatedAccount;
          }
          return account;
        });
        setState({ state: { ...state, accounts: updatedAccountsList } });
        // @note: this is our simple cache invalidation!!
        territoryAccountsCacheController.clearCached();
        return updatedAccount;
      },
      setPagination: (pagination) => {
        setState((state) => ({
          state: {
            ...state.state,
            pagination: {
              ...state.state.pagination,
              ...pagination,
            } as TerritoryFiltersState["pagination"],
          },
        }));
      },
      getAccountById: async (accountId) => {
        const { state } = getState();
        const currentAccount = state.accounts?.find(
          (account) => account.id === accountId,
        );
        if (currentAccount) return currentAccount;
        const account = await WhitespaceAPI.whitespace.getAccountById(
          accountId,
        );
        return account;
      },
      onToggleAccountSelection: (accountIds) => {
        // this handle three cases:
        // 1. accountsIds is an array of ids
        // 2. accountsIds is a single id
        // 3. accountsIds is [] it means that we want to clear the selection
        const { state } = getState();

        // if boolean was passed it will consider it as flag, to select all/total accounts
        if (typeof accountIds === "boolean") {
          setState({
            state: {
              ...state,
              selectionState: {
                isSelectAll: accountIds,
              },
            },
          });
        }

        const currentSelected = state.selectionState?.selected || {};

        if (Array.isArray(accountIds)) {
          if (!accountIds.length) {
            return setState({
              state: { ...state, selectionState: { selected: {} } },
            });
          }

          const newSelectedRows = accountIds.reduce((acc, rowId: string) => {
            acc[rowId] = true;
            return acc;
          }, {} as { [key: string]: boolean });
          setState({
            state: {
              ...state,
              selectionState: {
                selected: { ...currentSelected, ...newSelectedRows },
              },
            },
          });
          return;
        }

        if (typeof accountIds !== "string") return;

        const accountId = accountIds;
        const isSelected = currentSelected[accountId];
        const isSelectAll = state.selectionState?.isSelectAll;
        setState({
          state: {
            ...state,
            selectionState: {
              isSelectAll,
              selected: { ...currentSelected, [accountId]: !isSelected },
            },
          },
        });
      },

      onRemoveAccountSelection: (accountIds) => {
        if (!Array.isArray(accountIds)) return;

        const { state } = getState();
        const currentSelected = state.selectionState?.selected || {};

        const newSelectedAccounts = Object.fromEntries(
          Object.entries(currentSelected).filter(
            ([key, value]) => value && !accountIds.includes(key),
          ),
        );
        setState({
          state: {
            ...state,
            selectionState: { selected: newSelectedAccounts },
          },
        });
      },
      updateVisibleColumns: async (columns) => {
        const { actions, state } = getState();
        userStorage.set(`${TERRITORY_COLUMN_MAP}`, columns);
        setState(({ state }) => ({
          state: { ...state, columnsMeta: columns as any },
        }));
        await delay(400);
        const filters = formatDynamicFilters(
          removeInvalidObjectTypes(state.filters as DynamicFilterT),
        );
        const { pagination } = state;
        const filtersWithPagination = {
          ...filters,
          limit: pagination?.pageSize,
          offset: (pagination.currentPage - 1) * pagination.pageSize,
        } as TerritoryFilters & { columnInfo?: { Account: DynFilterColumn } };
        const columnsToSave = cleanedColumnsToView(state.columnsMeta);
        const columnInfo = columnsToSave
          ? { Account: columnsToSave }
          : undefined;
        await TerritoryAPI.dynamicTable(
          filtersWithPagination,
          "",
          0,
          columnInfo,
        );
      },
      getUserAccountsPerPage: async ({ limit, offset }) => {
        const { state } = getState();
        const { filters } = state;
        const convertedFilters = convertToCompatibleTerritoryFilters(
          filters as TerritoryFilters,
        );
        const response = await TerritoryAPI.filters(
          { ...convertedFilters, limit, offset },
          false,
        );
        if (response.error) {
          throw new Error(response.error);
        }
        return response?.results;
      },
      getAllUserAccounts: async (tableId: string = "") => {
        const { state } = getState();
        const { pagination } = state;
        const filters = formatDynamicFilters(
          removeInvalidObjectTypes(state.filters as DynamicFilterT),
        );

        const accountsPerCall = 5_000;
        const totalCalls = Math.ceil(pagination.total / accountsPerCall);

        const calls = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < totalCalls; i++) {
          calls.push(
            TerritoryAPI.dynamicTable(
              {
                ...filters,
                limit: accountsPerCall,
                offset: i * accountsPerCall,
              },
              tableId,
            ),
          );
        }
        const responses = await Promise.all(calls);
        const result: DynamicAccount[] = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const response of responses) {
          if (!response.error) {
            result.push(...response.results);
          }
        }

        return result || [];
      },
      getAccountQualityStats: async ({ userIds }: { userIds: string[] }) => {
        const accountOwners = userIds as TerritoryFilters["accountOwners"];
        setState({ isLoading: true });
        const filtersWithPagination = {
          accountOwners,
          limit: 0,
          offset: 0,
        };
        const lowSignalAccounts = {
          ...filtersWithPagination,
          pipelineSignals: [PipelineSignalEnums.Low],
        } as TerritoryFilters;
        const mediumSignalAccounts = {
          ...filtersWithPagination,
          pipelineSignals: [PipelineSignalEnums.Medium],
        } as TerritoryFilters;
        const strongSignalAccounts = {
          ...filtersWithPagination,
          pipelineSignals: [PipelineSignalEnums.Strong],
        } as TerritoryFilters;
        const lowQualityAccountResult = TerritoryAPI.filters(
          lowSignalAccounts,
          false,
        );
        const mediumQualityAccountResult = TerritoryAPI.filters(
          mediumSignalAccounts,
          false,
        );
        const highQualityAccountResult = TerritoryAPI.filters(
          strongSignalAccounts,
          false,
        );
        const [low, mid, high] = await Promise.all([
          lowQualityAccountResult,
          mediumQualityAccountResult,
          highQualityAccountResult,
        ]);
        setState({ isLoading: false });
        return [low.total || 0, mid.total || 0, high.total || 0];
      },
      getAccountIdsBySelectedVendors: async ({ searchQuery }, options) => {
        const response = await TerritoryAPI.accountsProductsFilter(
          { searchQuery },
          options,
        );
        const resultsForVendors = response?.groupedProducts || [];
        return resultsForVendors;
      },

      setInitialFilterValues: (newInitialState = {}) => {
        const { state, initialState } = getState();
        setState({
          ...state,
          initialState: {
            ...initialState,
            filters: { ...initialState.filters, ...newInitialState },
          },
        });
      },

      setAllFiltersDetails: ({ name, value }) => {
        const {
          state,
          state: { allFiltersDetails, filters },
        } = getState();
        const isAccountOwners = name === "accountOwners";
        const labelCount = (isAccountOwners ? filters?.accountOwners?.length : value) || 0;
        const label = allFiltersDetails[name as keyof typeof allFiltersDetails]?.getLabel?.(
          labelCount,
        );
        const updatedSpecificFilter = {
          ...allFiltersDetails[name as keyof typeof allFiltersDetails],
          label,
          count: value,
        };

        setState({
          state: {
            ...state,
            allFiltersDetails: {
              ...allFiltersDetails,
              [name]: updatedSpecificFilter,
            },
          },
        });
      },
      archiveAccounts: async () => {
        const { state } = getState();
        const currentSelected = state.selectionState?.selected || {};
        const selectedAccountIds = Object.keys(currentSelected).filter(
          (key) => currentSelected[key],
        );
        const { success } = await TerritoryAPI.archiveAccounts({
          accountIds: selectedAccountIds,
        });
        if (success) {
          const { accounts } = state;
          const updatedAccounts = (accounts ?? []).filter(
            (account) => !selectedAccountIds.includes(account.id),
          );

          const updatedSelected = Object.fromEntries(
            Object.entries(currentSelected).filter(
              ([key]) => !selectedAccountIds.includes(key),
            ),
          );
          setState({
            state: {
              ...state,
              accounts: updatedAccounts,
              selectionState: { selected: updatedSelected },
            },
          });
        }
      },
      deleteAccounts: async () => {
        const { state } = getState();
        const currentSelected = state.selectionState?.selected || {};
        const selectedAccountIds = Object.keys(currentSelected).filter(
          (key) => currentSelected[key],
        );
        const { success } = await TerritoryAPI.deleteAccounts({
          accountIds: selectedAccountIds,
        });
        if (success) {
          const { accounts } = state;
          const updatedAccounts = (accounts ?? []).filter(
            (account) => !selectedAccountIds.includes(account.id),
          );

          const updatedSelected = Object.fromEntries(
            Object.entries(currentSelected).filter(
              ([key]) => !selectedAccountIds.includes(key),
            ),
          );
          setState({
            state: {
              ...state,
              accounts: updatedAccounts,
              selectionState: { selected: updatedSelected },
            },
          });
        }
      },
      addAccountsToSF: async () => {
        const { state } = getState();
        const currentSelected = state.selectionState?.selected || {};
        const selectedAccountIds = Object.keys(currentSelected).filter(
          (key) => currentSelected[key],
        );

        const selectedAccsNotInSF = selectedAccountIds.filter((id) => {
          const account = (state.accounts || []).find((acc) => acc.id === id);
          return account?.source.name !== ConnectionName.Salesforce
        });

        const updatedAccounts: Account[] = (state.accounts || []).map(
          (account) => {
            if (selectedAccsNotInSF.includes(account.id)) {
              return {
                ...account,
                source: { name: ConnectionName.Salesforce, objectId: account.id },
              };
            }
            return account;
          },
        );

        const { success } = await TerritoryAPI.addAccountsToSF({
          accountIds: selectedAccsNotInSF,
        });
        if (success) {
          setState({
            state: {
              ...state,
              accounts: updatedAccounts,
            },
          });
        }
      },
      listViews: async () => {
        const viewsList = await Views.getViews();
        const views = [{
          id: "",
          name: "All Accounts",
          filters: defaultTerritoryFiltersObject,
        },
        ...viewsList
        ] as TableViewT[];

        setState((state) => ({
          state: {
            ...state.state,
            views
          }
        }));
        return views;
      },
      createView: async (name: string) => {
        const { state } = getState();
        const tempId = String(new Date())
        const filters = formatDynamicFilters(
          JSON.parse(JSON.stringify(state.filters)) as DynamicFilterT,
        ) as DynamicFilterT;

        setState((state) => ({
          state: {
            ...state.state,
            views: [
              ...(state.state.views || []),
              {
                id: tempId,
                name: name,
                filters: filters
              }
            ]
          }
        }));
        const resp = await Views.createView(filters, name);
        setState((state) => {

          const cleanedViews = (state.state.views || []).filter(view => view.id !== tempId);
          if (resp.success) cleanedViews.push({
            id: (resp.response as any)?.id,
            name: name,
            filters: filters
          });

          return {
            state: {
              ...state.state,
              views: cleanedViews
            }
          }
        });
        return resp;
      },
      getView: async (id) => {
        const { state } = getState();
        const currentViews = state.views || [];
        let targetView = currentViews.find((view) => view.id === id);
        if (!targetView && id) {
          targetView = await Views.getView(id);
          if (targetView?.filters) {
            const revicView = {
              id: targetView.id || id,
              name: targetView?.name || "Chatbot generated",
              filters: targetView?.filters,
            } as TableViewT;
            setState((state) => {
              const isExists = state.state?.views?.find((view) => view.id === id);
              return {
                state: {
                  ...state.state, views: isExists ? state.state.views : [...state.state.views, revicView]
                }
              }
            });
          }
          return
        }
        return targetView;
      },
      updateFiltersView: async (id) => {
        const { state, actions } = getState();
        const targetView = await actions.getView(id!);
        if (!targetView) return;
        const filters = formatDynamicFilters(
          targetView?.filters as DynamicFilterT,
        ) as DynamicFilterT;
        setState({
          state: {
            ...state,
            filters: targetView?.filters as TerritoryFilters,
          }
        });
      },
      deleteView: async (id: string) => {
        const { state } = getState();
        const currentViews = state.views || [];
        const resp = await Views.deleteView(id);
        const updatedViews = currentViews.filter((view) => view.id !== id);
        setState({ state: { ...state, views: updatedViews } });
        return resp;
      },
      updateView: async (id) => {
        const { state } = getState();
        const filters = formatDynamicFilters(
          JSON.parse(JSON.stringify(state.filters)) as DynamicFilterT,
        ) as DynamicFilterT;

        const resp = await Views.updateView(filters as any, id);
        if (!resp.success) return resp

        setState((state) => {
          const currentViews = state.state.views || [];
          const updatedViews = currentViews.map((view) => {
            if (view.id === id) {
              return {
                ...view,
                filters: filters
              }
            }
            return view
          });
          return {
            state: {
              ...state.state,
              views: updatedViews,
            }
          }
        });
        return resp;
      },
    },
  };
});

export default useTerritoryFilters;
